import { v4 } from 'uuid'

/**
 * Get a random UUID v4 string.
 *
 * @returns A random UUID v4 string.
 */
export default function useV4() {
  return v4()
}
