const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

/**
 * Format a number as a USD string.
 *
 * @param value - The value to format.
 * @returns A formatted USD string or "Free".
 */
export default function useMoneyFormat(value: number | string) {
  const number = Number(value)

  return number === 0 ? 'Free' : formatter.format(number)
}
